import React from 'react'

import { Link } from 'react-router-dom'

import '../style/Main.scss'

const Main = () => {
  return (
    <div>
      <div className='background-container'>
        <h1>GALO MIGONI</h1>
        <h3>FRONTEND DEVELOPER</h3>
      </div>
      <section className='first-section'>
        <div className='image-header-container'>
          <picture className='first-image-container'>
            <source loading='lazy' srcSet='./images/portfolio-photo.webp' alt='image-portfolio' type="image
            /webp" />
            <img className='image-fc' src="./images/portfolio-photo.webp" alt="" />
          </picture>
          <h1>Hi!👋🏻</h1>
          <p className='first-p'>My name is Luciano Galo, and I am a Frontend Web Developer. I will develop your website using the best practices so that you can achieve all your goals!</p>
        </div>
        <div className='main-info-container'>
          <div className='info-container'>
            <h2>Education</h2>
            <h3>2022-now</h3>
            <p>Self-Taught courses about NodeJS, React & SASS</p>
            <h3>2023-2024</h3>
            <p>Harvard CS50: Introduction to Computer Science</p>
            <h3>2023-now</h3>
            <p>Systems Engineering - UAI University</p>
          </div>
          <div className='snd-info-container fst-grid'>
            <h2>Programming Skills</h2>
            <div className='info-container-grid'>
              <img srcSet="./images/p-skills/html_1051277.webp" alt="HTML" type="image/webp" /><h3>HTML</h3>
              <img srcSet="./images/p-skills/js_5968292.webp" alt="JavaScript" /><h3>JavaScript</h3>
              <img srcSet="./images/p-skills/social_11516361.webp" alt="CSS" /><h3>CSS</h3>
              <img srcSet="./images/p-skills/sass_5968358.webp" alt="SASS" /><h3>SASS</h3>
              <img srcSet="./images/p-skills/physics_1126012.webp" alt="ReactJS" /><h3>ReactJS</h3>
              <img srcSet="./images/p-skills/nodejs-1.webp" alt="NodeJS" /><h3>NodeJS</h3>
              <img srcSet="./images/p-skills/python_5968350.webp" alt="Python" /><h3>Python</h3>
            </div>
          </div>
          <div className='snd-info-container snd-grid'>
            <h2>Software Skills</h2>
            <div className='info-container-grid'>
              <img srcSet="./images/s-skills/illustrator_5968472.webp" alt="Adobe Illustrator" /><h3>Adobe Illustrator</h3>
              <img srcSet="./images/s-skills/xd_5968559.webp" alt="Adobe XD" /><h3>Adobe XD</h3>
              <img srcSet="./images/s-skills/photoshop_5968520.webp" alt="Adobe Photoshop" /><h3>Adobe Photoshop</h3>
              <img srcSet="./images/s-skills/icons8-figura-48.webp" alt="Figma" /><h3>Figma</h3>
              <img srcSet="./images/s-skills/icons8-visual-studio-code-2019-48.webp" alt="Visual Studio Code" /><h3>Visual Studio Code</h3>
            </div>
          </div>
          <div className='contact-info-container'>
            <h2>Contact</h2>
            <div><img src="./icons/contact/email.svg" alt="" /><h3>galoweb11@gmail.com</h3></div>
            <div><img src="./icons/contact/linkedin.svg" alt="" /><h3>Luciano Migoni</h3></div>
          </div>
        </div>
      </section>
      <section className='second-section'>
        <div className='image-section'>
          <div className='fst-image'></div>
          <div className='black-fst'></div>
          <div className='snd-image'></div>
          <div className='black-snd'></div>
        </div>
      </section>
      <section className='third-section'>
        <h1>MY SERVICES</h1>
        <div className='service-main-container'>
          <div className='service-info-container sic-grid-fst'>
            <h2>Personal Websites</h2>
            <img className='service-img' src="./images/service-image.webp" alt="Me editing a adobe XD Project :D" />
            <p>Make yourself known to the world with a professional and efficient portfolio</p>
            <button className='button-pricing'><Link to={"./pricing"}>See pricing</Link></button>
          </div>
          <div className='service-info-container sic-grid-snd'>
            <h2>Business Websites</h2>
            <p>High-quality website for your business, with multiple pages</p>
            <button className='button-pricing'><Link to={"./pricing"}>See pricing</Link></button>
          </div>
          <div className='service-info-container sic-grid-trd'>
            <h2>Diverse Websites</h2>
            <p>100% personalized websites for whatever your objective may be</p>
            <button className='button-pricing'><Link to={"./pricing"}>See pricing</Link></button>
          </div>
        </div>
      </section>
      <section className='fourth-section'>
        <div className='image-section'>
          <div className='fst-image'></div>
          <div className='black-fst'></div>
          <div className='snd-image'></div>
          <div className='black-snd'></div>
        </div>
      </section>
    </div>
  )
}

export default Main