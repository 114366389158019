import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Player } from '@lottiefiles/react-lottie-player';


import '../style/Contact.scss'

const validateForm = (formData) => {
  const errors = {};

  let regexName = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/
  let regexEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/ 

  if (formData.user_name.trim() === '') {
    errors.user_name_error = 'Please enter your name.'
  } else if (!regexName.test(formData.user_name)) {
    errors.user_name_error = 'Please insert a valid name.'
  }

  if (formData.user_email.trim() === '') {
    errors.user_email_error = 'Please enter your email address.'
  } else if (!regexEmail.test(formData.user_email)) {
    errors.user_email_error = 'Please insert a valid email adress.'
  }

  if (formData.message.trim() === '') {
    errors.message_error = 'Please enter a message.'
  } else if (formData.message.trim().length >= 700){
    errors.message_error = 'The message cannot exceed 700 characters in length'
  }

  return errors;
}

const Contact = () => {
  const [sended, setSended] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    subject: 'basic-plan',
    message: ''
  });
  const [errors, setErrors] = useState({
    user_name_error: '',
    user_email_error: '',
    message_error: ''
  });

  const form = useRef();
  const serviceId = "service_3273nzi"
  const templateId = "template_t1zo3fh"
  const publicKey = "RyJ_PBfLViWDrT7YS"

  const sendEmail = (e) => {
    e.preventDefault();

    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

      emailjs
        .sendForm(serviceId, templateId, form.current, {
          publicKey: publicKey,
        })
        .then(
          () => {
            setSended(true);
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  return (
    <section className='form-section'>
      {sended ? (
        <div className='sended-div'>
          <Player
            autoplay
            src="https://lottie.host/eaa83d60-e564-44d4-b164-eb051b97cd74/nTw8z4to6g.json"
            keepLastFrame
            style={{ height: "20rem" }}
          />
          <h1>Message Sent</h1>
          <h1 id='ty-header'>Thank you!</h1>
        </div>
      ) : (
        <form className='contact-form' ref={form} onSubmit={sendEmail}>
          <label htmlFor="name"></label>
          <input 
            placeholder='Name' 
            type="text" 
            name="user_name" 
            value={formData.user_name}
            onChange={handleInputChange}
          />
          {errors.user_name_error && <div class="form-validate" role="alert">
            {errors.user_name_error}
          </div>}

          <label htmlFor="email"></label>
          <input 
            placeholder='Email' 
            type="email" 
            name="user_email" 
            value={formData.user_email}
            onChange={handleInputChange}
          />
          {errors.user_email_error && <div class="form-validate" role="alert">
            {errors.user_email_error}
          </div>}

          <label htmlFor="subject"></label>
          <select className='select-subject' name="subject">
            <option value="basic-plan">Basic Plan</option>
            <option value="standard-plan">Standard Plan</option>
            <option value="premium-plan">Premium Plan</option>
            <option value="custom-plan">Custom Plan</option>
          </select>

          <label htmlFor="message"></label>
          <textarea
            placeholder='Message' 
            type="text" 
            name="message" 
            value={formData.message}
            onChange={handleInputChange}
          />
          {errors.message_error && <div class="form-validate" role="alert">
            {errors.message_error}
          </div>}

          <button id='submit' type='submit' value="Send">Send Message</button>
        </form>
      )}
    </section>
  )
}

export default Contact