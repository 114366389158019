import React from 'react'

import { Link } from 'react-router-dom'

import '../style/Footer.scss'

const Footer = () => {
    return (
        <section className='footer'>
            <div className='contact-container'>
            <h1>Contact</h1>
            <div className='grid-contact-container'>
                <img src="./icons/contact/email-w.svg" alt="Email" /><h2>galoweb11@gmail.com</h2>
                <img src="./icons/contact/linkedin-w.svg" alt="Linkedin" /><h2>Luciano Galo Migoni</h2>
            </div>
            </div>
            <div className='index-container'>
            <h1>Index</h1>
                <ul>
                    <li><Link to={"./"} href="">Home</Link></li>
                    <li><Link to={"./pricing"} href="">Pricing</Link></li>
                    <li><Link to={"./contact"} href="">Contact</Link></li>
                </ul>
            </div>
        </section>
    )
}

export default Footer