import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';

const Notfh = () => {
    return (
        <div className='page-not-found'>
            <Player
                autoplay
                loop
                src="https://lottie.host/15ffdbcb-2a09-4ba5-8db0-7f2fa62cb93a/loyBBVaVIa.json"
            />
        </div>
    )
}

export default Notfh