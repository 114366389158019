import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'

import NavBar from './components/NavBar'
import Footer from './components/Footer'

import Main from './components/Main'
import Pricing from './components/Pricing'
import Contact from './components/Contact'

import Notfh from './components/Notfh'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path='/' element={<Main />}/>
        <Route path='/pricing' element={<Pricing />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='*' element={<Notfh></Notfh>}/>
      </Routes>
      <Footer />
    </Router>
  )
}

export default App