import React from 'react'

import { Link } from 'react-router-dom'

import '../style/Pricing.scss'

const Pricing = () => {
    return (
        <section className='pricing-section'>
            <div className='pricing-container'>
                <div className='fst-plan'>
                    <h1>Basic Plan</h1>
                    <h3 className='snd-header'>2 Pages fully responsive</h3>
                    <div className='prices'>
                        <h2>$79.95</h2>
                        <h4>$139.95</h4>
                    </div>
                    <div className='features'>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Website Desing</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Responsive</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Functional Website</h3>
                        <img src="./icons/close.svg" alt="tick" /><h3 style={{ color: "#505050" }}>Logo Creation</h3>
                        <img src="./icons/close.svg" alt="tick" /><h3 style={{ color: "#505050" }}>API Rest</h3>
                    </div>
                    <div className='contact-me'>
                        <button><Link className='nmc-contact' to={"../contact"}>Contact Me!</Link></button>
                    </div>
                </div>
                <div className='snd-plan'>
                    <h1>Standard Plan</h1>
                    <h3 className='snd-header'>3-5 Pages fully responsive</h3>
                    <div className='prices'>
                        <h2>$120.95</h2>
                        <h4>$180.95</h4>
                    </div>
                    <div className='features'>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Website Desing</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Responsive</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Functional Website</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Logo Creation</h3>
                        <img src="./icons/close.svg" alt="tick" /><h3 style={{ color: "#505050" }}>API Rest</h3>
                    </div>
                    <div className='contact-me'>
                        <button><Link className='nmc-contact' to={"../contact"}>Contact Me!</Link></button>
                    </div>
                </div>
                <div className='prm-plan'>
                    <h1>Premium Plan</h1>
                    <h3 className='snd-header'>6-8 pages fully responsive</h3>
                    <div className='prices'>
                        <h2>$170.95</h2>
                        <h4>$255.95</h4>
                    </div>
                    <div className='features'>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Website Desing</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Responsive</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Functional Website</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>Logo Creation</h3>
                        <img src="./icons/correct.svg" alt="tick" /><h3>API Rest</h3>
                    </div>
                    <div className='contact-me'>
                        <button><Link to={"../contact"}>Contact Me!</Link></button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing