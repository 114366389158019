import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import '../style/NavBar.scss'

const NavBar = () => {
  const [menuClicked, setMenuClicked] = useState(false);
  return (
    <div>
      <nav>
        {/* <div className='white-space'></div> */}
        <div className='nav-bar'>
          <img onClick={(e) => setMenuClicked(!menuClicked)} className='nav-bar_menu_image' src="./icons/menu.svg" alt="menu" />
          <h1 className='nav-bar_header'>GALOWEB</h1>
          <div className='menu-options-desktop'>
            <Link to={"./"}>Home</Link>
            <Link to={"./pricing"}>Pricing</Link>
            <Link to={"./contact"}>Contact</Link>
          </div>
          <h2>2024&copy;</h2>
        </div>
        <div className={menuClicked ? 'menu-clicked' : 'menu-not-clicked'}>
          <div className='menu-options'>
            <img onClick={(e) => setMenuClicked(false)} className='close-menu' src="./icons/x.svg" alt="close" />
            <Link onClick={(e) => setMenuClicked(!menuClicked)} to={"./"}>Home</Link>
            <Link onClick={(e) => setMenuClicked(!menuClicked)} to={"./pricing"}>Pricing</Link>
            <Link onClick={(e) => setMenuClicked(!menuClicked)} to={"./contact"}>Contact</Link>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavBar